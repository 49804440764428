import Header from "../../components/Common/Header";
import Footer from "../../components/Common/Footer";
import Menu from "../../components/Common/Menu";
import WeContent from "./components/weContent";
import type { HeadFC } from "gatsby";
import React from "react";

const IndexPage = () => {
  return (
    <main>
      <Header />
      <Menu />
      <WeContent />
      <Footer />
    </main>
  )
}

export default IndexPage;

export const Head: HeadFC = () => <title>Quienes somos - LaBotana : Noticias y chismes de los famosos, desde 1999</title>
