import { Row, Col } from 'reactstrap';
import React from 'react';

const WeContent = () => {
  return (
    <Row className="white-bg pt-3 pb-3">
      <Col md={8} className="d-flex  flex-column text-left">
        <h2>Quienes somos</h2>

        <div>
          <span data-amp-original-style="font-size: medium;"><b>LaBotana.com</b> nació a mediados de 1999 como una revista electrónica de farándula, dedicada a las noticias de entretenimiento y chismes de los famosos.</span>
          <p><span data-amp-original-style="font-size: medium;">Inauguramos nuestro primer sitio web en febrero del año 2000, siendo, desde su lanzamiento, uno de los principales portales de México, dedicados a las noticias del espectáculo.</span></p>
          <p><strong>Para alianzas, publicidad, publinoticias y más, envíanos un email.</strong></p>
          <p><b>Email:</b> <span data-amp-original-style="color: blue;"><span id="cloak62165"><a href="mailto:labotana@gmail.com">labotana@gmail.com</a></span></span></p>
        </div>
      </Col>
    </Row>
  );
}

export default WeContent;
